// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');


// Bootstrap

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
@import '~bootstrap/scss/bootstrap';


// Mini Reset
@import "minireset";

// Base SCS
@import "mixins";
// Variables
@import 'variables';
@import "typography";
@import "base";
@import "global";