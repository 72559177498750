@font-face {
    font-family: 'azossans_black';
    src: url('../fonts/AzoSans-Black.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'azossans_bold';
    src: url('../fonts/AzoSans-Bold.otf') format("opentype");
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'azossans_light';
    src: url('../fonts/AzoSans-Light.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'azossans_lightitalic';
    src: url('../fonts/AzoSans-LightItalic.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'azossans_medium';
    src: url('../fonts/AzoSans-Medium.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'azossans_mediumitalic';
    src: url('../fonts/AzoSans-MediumItalic.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'azossans_regular';
    src: url('../fonts/AzoSans-Regular.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}

%black {
    font-family: 'azossans_black'; 
}
%bold {
    font-family: 'azossans_bold';
}
%light {
    font-family: 'azossans_light';
}
%lightitalic {
    font-family: 'azossans_lightitalic';
}
%medium {
    font-family: 'azossans_medium';
}
%mediumitalic {
    font-family: 'azossans_mediumitalic';
}
%regular {
    font-family: 'azossans_regular';
}

