header {
    .full-header {
        &.register-page {
            background-image: url(../images/header-2.png);
        }
        background-image: url(../images/header-3-mobile.png);
        @include media-breakpoint-up(md) {
            background-image: url(../images/header-3@2x.png);
        }
        background-color: $black;
        background-size: cover;
        background-position: center;
        padding-bottom: 100px;
    }
    .logo {
        svg {
            max-width: 90%;
        }
    }
    .site-logo {
        > svg {
            width: 60%;
            height: auto;
            @include media-breakpoint-up(md) {
                width: 200px;
            }
        }
    }
    .title {
        text-transform: uppercase;
        color: $white;
        strong {
            @extend %bold;
        }
        h2 {
            @extend %light;
        }
        strong,
        h2 {
            color: $white;
            font-size: 24px;
            line-height: 30px;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 100px;
            strong,
            h2 {
                font-size: 40px;
                line-height: 48px;
            }
        }
    }
}
.tira {
    z-index: 99;
    background: url(../images/tira2.png) repeat-x center top;
    background-size: cover;
    position: absolute;
    top: -140px;
    left: 0;
    height: 140px;
    width: 100%;

    @media (max-width: 769px) {
        height: 80px;
    }
}
footer {
    .footer-img {
        position: relative;
        width: 100%;
        background: #000;
        text-align: center;

        img {
            position: relative;
            z-index: 9;
            padding-top: 7px;
        }
    }
    .socials {
        display: flex;
        margin-left: 0;
        list-style-type: none;
        padding: 0px;

        .socials__item {
            margin-right: 8px;
            svg {
                margin-top: 5px;
                margin-right: 5px;
                @include media-breakpoint-up(md) {
                    margin-top: 0px;
                    width: 95px;
                    height: auto;
                }
            }
            img {
                @include media-breakpoint-up(md) {
                    width: 20px;
                    height: 20px;
                }
            }
            a {
                @include media-breakpoint-down(sm) {
                    line-height: 30px;
                }
            }
        }
    }

    p {
        &,
        strong {
            color: #464646;
            font-size: 12px;
            line-height: 20px;
        }
    }
}

h1 {
    @extend %light;
    strong {
        @extend %bold;
    }
    &,
    strong {
        color: #707070;
        font-size: 28px;
        line-height: 36px;
    }
    text-transform: uppercase;
    @include media-breakpoint-up(md) {
        &,
        strong {
            font-size: 45px;
            line-height: 50px;
        }
    }
}
.quote {
    svg {
        height: auto;
        max-width: 100%;
    }
}

//stage 2
#sugestao {
    .quotesvg {
        @include media-breakpoint-down(sm) {
            width: 28px;
            float: right;
            height: auto;
            margin-top: -24px !important;
            margin-left: 0px !important;
            margin-right: 10px;
        }
    }
}

.top-header-table {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    * {
        color: $white;
        font-size: 16px;
        line-height: 20px;
    }
    h2 {
        strong {
            @extend %bold;
            font-size: 26px;
            line-height: 32px;
            @include media-breakpoint-up(md) {
                font-size: 30px;
                line-height: 32px;
            }
        }
    }
    strong {
        @extend %bold;
    }
    border: 1px solid $white;
    .top-table {
        border-bottom: 1px solid $white;
    }
    @include media-breakpoint-up(md) {
        .col-md-6:nth-child(2) {
            border-right: 1px solid $white;
        }
    }
    @include media-breakpoint-down(sm) {
        .col-md-6:nth-child(3) {
            border-top: 1px solid $white;
        }
    }
    div > p:nth-child(1) {
        margin-bottom: 15px;
    }
}

@include media-breakpoint-down(sm) {
    header .full-header.home {
        background-color: #000;
        background-position: top;
        background-repeat: no-repeat;
    }
}

#monthres {
    .border-hack {
        left: -16.6666666667vw;
        top: 0px;
        width: 100vw;
    }
    .border-right {
        border-color: #707070 !important;
        border-width: 0px !important;
        @include media-breakpoint-up(md) {
            border-width: 1px !important;
        }
    }
    .border-left,
    .border-bottom,
    .border-top {
        border-color: #555555 !important;
    }
    .image-marker {
        top: 0px;
        z-index: 9;
        height: auto;
        max-width: 100%;
    }
    h1 {
        font-size: 30px;
        line-height: 43px;
        @include media-breakpoint-up(md) {
            font-size: 44px;
            line-height: 53px;
        }
    }
    h3 {
        @extend %light;
        color: $red;
        font-size: 30px;
        line-height: 43px;
        @include media-breakpoint-up(md) {
            font-size: 44px;
            line-height: 53px;
        }
    }
    .tab-content {
        p {
            color: #555555;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            @include media-breakpoint-up(md) {
                text-align: left;
            }
        }
    }
    .user-quote {
        .img-avatar {
            max-width: 50px;
        }
        .user-infos {
            .user-name {
                @extend %bold;
            }
            .user-name,
            .user-type {
                font-size: 17px;
                line-height: 20px;
                @include media-breakpoint-up(md) {
                    font-size: 20px;
                    line-height: 24px;
                }
            }
        }
    }
    .address {
        &,
        strong {
            font-size: 15px;
            line-height: 18px;
            @include media-breakpoint-up(md) {
                font-size: 17px;
                line-height: 22px;
            }
        }
    }
    .nav-pills {
        .nav-link {
            text-decoration: none;
            text-transform: uppercase;
            color: #555555;
            font-size: 30px;
            line-height: 40px;
            @extend %light;
            border-radius: 0px;
            margin-bottom: -3px;
            border-bottom: 7px solid transparent;
            padding: 15px 25px;
            &.active {
                background: none;
                border-bottom: 7px solid $red;
                @extend %bold;
            }
        }
    }
    .tabs-to-dropdown {
        .dropdown {
            width: 100%;
            position: relative;
            &.show {
                svg {
                    transform: rotate(180deg);
                }
            }
            button {
                border-radius: 0px;
                text-align: left;
                border-bottom: 1px solid #707070 !important;
                width: 100%;
                position: relative;
                padding: 13px 0px;
                margin-top: 15px;
                span {
                    text-decoration: none;
                    text-transform: uppercase;
                    color: #555555;
                    font-size: 22px;
                    line-height: 32px;
                    border-radius: 0px;
                    margin-bottom: -3px;
                    padding: 14px 18px;
                    background: none;
                    border-bottom: 7px solid $red;
                    @extend %bold;
                }
                svg {
                    position: absolute;
                    right: 0px;
                    top: 25px;
                }

                &::after {
                    border: 0px;
                }
            }
            .dropdown-menu {
                position: relative !important;
                transform: none !important;
                max-height: 0px;
                overflow: hidden;
                display: block;
                transition: all 1s;
                padding: 0px;
                border: 0px;
                width: 100%;
                &.show {
                    max-height: 300px;
                }
                .dropdown-item {
                    text-decoration: none;
                    text-transform: uppercase;
                    color: #555555;
                    font-size: 22px;
                    line-height: 32px;
                    border-radius: 0px;
                    padding: 14px 18px;
                    background: none;
                    border-bottom: 1px solid #707070 !important;
                    width: 100%;
                    @extend %light;
                }
                .dropdown-item.odd {
                    background: #f2f2f2;
                }
            }
            .line {
                width: 100%;
                height: 1px;
                display: block;
                border-bottom: 1px solid #707070 !important;
            }
        }
    }
}

.marker-above {
    background: $red;
    position: absolute;
    top: 0px;
    right: 5%;
    padding: 10px;
    div {
        color: $white;
        font-size: 10px;
        line-height: 12px;
        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 19px;
        }
        &.year {
            @extend %bold;
        }
    }
    svg {
        max-width: 100%;
        height: auto;
        margin-top: 20px;
    }
}

.small-quote {
    max-width: 25px;
    height: auto;
    margin-bottom: 10px;
    &.reverse {
        transform: rotate(180deg);
    }
}

.vote-widget {
    text-align: left;
    display: inline-block;
    svg {
        float: left;
        max-width: 30px;
        height: auto;
    }
    > div {
        margin-left: 35px;
        strong {
            font-size: 20px;
            line-height: 16px;
            @extend %bold;
        }
        div {
            font-size: 14px;
            line-height: 18px;
            @extend %light;
        }
    }
    &.vertical {
        margin: 10px 0px;
        > div {
            line-height: 38px;
            div {
                display: inline-block;
            }
        }
    }
}

#middle-image {
    img {
        opacity: 0;
    }
    background-image: url(../images/middle@2x.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

//home menu
.float-menu-parent {
    @include media-breakpoint-down(sm) {
        &.active {
            background: $black;
            margin-right: -15px;
            margin-left: -15px;
            padding-bottom: 30px;
        }
    }
    .float-menu {
        width: 280px;
        max-width: 100%;
        margin: 0px auto;
        overflow: hidden;
        @include media-breakpoint-up(md) {
            float: right;
        }
        .burguer {
            max-height: 0px;
            overflow: hidden;

            @include media-breakpoint-down(sm) {
                width: 100vw;
                margin-left: calc(-50vw + 50%) !important;
            }
        }
        .button {
            .close-menu {
                display: none;
            }
            background: $red;
            width: 50%;
            border-radius: 10px;
            padding: 9px 0px;
            text-align: center;
            float: right;
            margin-left: -5%;
            z-index: 2;
            position: relative;
            svg {
                height: 20px;
                width: auto;
            }
        }
        .search {
            width: 55%;
            position: relative;
            min-height: 38px;
            text-align: center;
            float: right;
            svg {
                height: 30px;
                width: auto;
            }
            .inside {
                border-radius: 10px;
                background: rgba($color: #4d4d4d, $alpha: 0.8);
                @include media-breakpoint-up(md) {
                    background: rgba($color: #fff, $alpha: 0.5);
                }
                padding: 4px 0px;
                form {
                    display: none;
                }
            }
        }
        &.active {
            position: relative;
            overflow: initial;
            .button {
                .open {
                    display: none;
                }
                .close-menu {
                    display: inline-block;
                }
            }
            .burguer {
                max-height: 300px;
                transition: all 1s;
                clear: both;
                margin: 25px 0px;
                border-top: 1px solid #fff;
                margin-top: 60px;

                @include media-breakpoint-up(md) {
                    position: absolute;
                    border: 0px;
                    top: 45px;
                    margin-top: 25px;
                }
                .options {
                    a {
                        @extend %black;
                        color: $white;
                        display: block;
                        border-bottom: 1px solid #fff;
                        padding: 25px;
                        font-size: 16px;
                        line-height: 20px;
                        text-decoration: none;

                        @include media-breakpoint-up(md) {
                            padding: 10px 60px;
                            border: 0px;
                            text-align: right;
                            svg {
                                margin-left: 14px;
                            }
                            &:nth-child(1) {
                                svg {
                                    margin-left: 9px;
                                    margin-right: -2px;
                                }
                            }
                            font-size: 14px;
                            line-height: 17px;
                        }

                        @include media-breakpoint-down(sm) {
                            svg {
                                float: left;
                                margin-right: 14px;
                            }
                            &:nth-child(1) {
                                svg {
                                    margin-right: 12px;
                                    margin-left: -2px;
                                }
                            }
                        }
                    }
                }
            }
            .search {
                @include media-breakpoint-down(sm) {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 100%;
                    .inside {
                        max-width: 100%;
                    }
                }
                .inside {
                    position: absolute;
                    right: 0px;
                    width: 290px;
                    form {
                        display: inline-block;
                        width: 70%;
                        input {
                            width: 100%;
                            border: 0px;
                            border-radius: 5px;
                            line-height: 25px;
                            padding: 0px 10px;
                            @extend %light;
                            font-size: 15px;
                        }
                    }
                    svg {
                        padding: 0px 20px;
                        @include media-breakpoint-up(md) {
                            padding: 0px 20px 0px 10px;
                        }
                    }
                }
            }
        }
    }
}

header.mini {
    .header-container-min {
        background: #2b2b2b url(../images/header-3@2x.png) no-repeat top;
        background-size: cover;
        display: flex;
        flex-direction: column;
        .header-logo {
            text-align: center;
            padding: 52px 0px;
        }
    }
}

.btn-fid-grey-custom {
    border: none;
    text-decoration: none;
    padding: 17px 0px;
    font-family: "AzoSans-Black";
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    border-radius: 8px;
    display: block;
    text-align: center;
    max-width: 170px;
    color: white !important;
    background-color: #555555;
}

button.btn-link {
    @extend %bold;
    padding: 0px;
    font-size: 14px;
    line-height: 18px;
    color: #555555;
    text-decoration: none;
    &:hover {
        color: #e6041e;
    }
}
#log {
    overflow: hidden;
}

.month-in-home {
    .likes-counter {
        .likes-counter-container {
            justify-content: flex-end;
        }
    }
}

.restaurant-item-flag-dog {
    position: absolute;
    top: 0;
    right: 5%;
    background-color: $primary-red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    max-width: 60px;
    padding: 0;

    span {
        svg {
            width: 22px;
        }
    }

    .restaurant-item-flag-title {
        &,
        div {
            font-size: 14px;
            line-height: 14px;
            color: white;
        }
        padding: 10px;

        .restaurant-item-flag-title-month {
            font-family: "AzoSans-Bold";
        }
    }
}
.restaurant-page {
    .vertical {
        .quote-container {
            padding-top: 0px !important;
            padding-bottom: 15px !important;
        }
    }
}

.reservations-item-info_likes {
    .likes-counter-container {
        justify-content: flex-end;
    }
}

.restaurant-item {
    .special {
        .restaurant-item-info {
            float: left;
            width: 70%;
            margin: 15px 0px 0px 0px !important;
            .restaurant-item-info_address {
                font-size: 20px;
                line-height: 20px;
            }

            @media (max-width: 1199.98px) {
                width: 100%;
            }
        }
        .restaurant-item-info_likes {
            float: left;
            width: 30%;

            @media (max-width: 1199.98px) {
                width: 100%;
            }
            margin: 10px 0px 0px 0px !important;
            span {
                display: none;
            }
            .month-reserv-icon {
                margin-right: 3px;
                width: 25px;
                height: 25px;
                svg {
                    width: 10px;
                }
            }
            .month-reserv-counter h4 {
                line-height: 28px !important;
                font-size: 15px !important;
            }
            .likes-counter-container {
                justify-content: flex-end !important;
            }
        }
    }
}
.approve-rest-list {
    .approve-btn {
        float: left;
        clear: both;
        margin-top: 15px;
    }
}
.restaurant-list_content-fitlers-container {
    .restaurant-list_content-filter-item {
        position: relative;
        &::after {
            content: " ";
            height: 1px;
            background: #555555;
            width: calc(50vw - 100%);
            position: absolute;
            left: calc(-50vw + 200%);
            bottom: -1px;
        }
        &:last-child::after {
            display: none;
        }
    }
}
@include media-breakpoint-down(md) {
    .restaurant-page__info-container {
        .restaurant-page__info-top {
            .likes-counter {
                width: 50%;
            }
            .approve-btn {
                width: 50%;
                margin-left: 0px !important;
            }
        }

        &.top-month {
            .restaurant-page__info-top {
                display: block !important;
                .approve-btn {
                    margin-top: 15px;
                }
            }
        }
    }
}

.approve-btn {
    &.approved {
        path {
            fill: $white;
        }
        .approve-btn-icon {
            background: $primary-red !important;
        }
    }
}
@media (max-width: 1199.98px) {
    .approve-rest-list {
        .approve-btn {
            &.approved {
                path {
                    fill: $primary-red !important;
                }
                .approve-btn-icon {
                    background: $white !important;
                }
            }
        }
    }
}

.restaurant-item-flag-dog.special {
    top: -15px;
    display: none;
    left: 0px;
    width: 50px;
    right: initial;
    span {
        padding-top: 70px;
    }
}

.restaurant-list_content-fitlers.collapse:not(.show) {
    @include media-breakpoint-up(md) {
        display: block !important;
    }
}
.top-approve {
    display: none;
}
@include media-breakpoint-down(md) {
    .restaurant-item-flag-dog.special {
        display: block;
    }
}
@media (max-width: 1199.98px) {
    .bottom-approve {
        display: none;
    }
    .top-approve {
        display: block;
    }
    .restaurant-item .special .restaurant-item-info_likes .likes-counter-container {
        justify-content: flex-start !important;
        .month-reserv-icon {
            width: 35px;
            height: 35px;
            margin-top: 4px;
        }
        .month-reserv-counter span {
            display: block;
        }
    }
    .approve-rest-list {
        .approve-btn {
            float: none;
            margin-top: 0px;
            background: $primary-red;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            &.toapprove {
                .approve-btn-icon {
                    background: $primary-red;
                    border: 1px solid #fff;
                    path {
                        fill: #fff;
                    }
                }
            }
            .approve-btn-container {
                text-align: left;
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                flex-direction: row;
                justify-content: center;
                width: 100%;
                padding: 5px;
            }
            span {
                color: #fff !important;
                font-family: "AzoSans-Regular" !important;
                font-size: 14px !important;
            }
        }
    }
}
.month-in-home.mobile {
    display: none;
}

@media (max-width: 769px) {
    .month-in-home.mobile,
    .month-reserv-restaurant-address {
        display: block;
    }

    .month-in-home.desktop {
        display: none;
    }
    .month-in-home .likes-counter .likes-counter-container {
        justify-content: center;
    }
}

//.reservations-item-info_title,
.recommendations-item__info-title,
.quote-user-name {
    text-transform: uppercase !important;
}
.z-index-2 {
    z-index: 2;
}
.z-index-3 {
    z-index: 3;
}

.f-bold {
    font-family: "AzoSans-Bold";
}

strong {
    @extend %bold;
}
#registo {
    .title {
        &,
        strong {
            text-transform: uppercase;
            font-size: 40px;
            line-height: 48px;
        }

        strong {
            @extend %bold;
        }
    }
    .info {
        &,
        strong {
            font-size: 11px;
            line-height: 14px;
        }
        a{
            font-size: 11px;
            line-height: 14px;
            text-decoration: underline;
            font-family: "AzoSans-Regular" !important;
        }
    }
}


body{
    overflow-x: hidden;
}